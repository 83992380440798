<template>
  <div v-if="this.$store.state.connectedToHub" class="draw-content">
    <div class="disconnect-modal" :key="this.$store.state.showDisconnect" v-if="this.$store.state.showDisconnect">
      <disconnect></disconnect>
    </div>    
    <div class="maintenance-modal" :key="this.$store.state.showMaintenance" v-if="this.$store.state.showMaintenance">
      <maintenance></maintenance>
    </div>
    <div class="jackpot-modal" :key="this.$store.state.showJackpot" v-if="this.$store.state.showJackpot">
      <jackpot></jackpot>
    </div>
      <div class="box-content" v-show="this.$store.state.isDrawVisible">
        <div class="scroll-restyle ng-tns-0-2">
          <app-root _nghost-kec-c90="" ng-version="13.2.6">
            <app-game
            >
              <div _content-1="" class="mw hide-chat-bar">
                <div _content-1="" class="mc">
                  <div _content-1="" class="w-100 h-100">
                    <div _content-1="" class="gc">
                      <div _content-1="" class="gp">
                        <div _content-1="" class="sb">
                          <!---->
                          <div _content-1="" class="pbw">
                            <div _content-1="" class="dc">
                            </div>
                            <div _content-1="" class="sc">
                              <app-play-board
                                id="crash-game" 
                                :drawVisible="isDrawVisible" 
                                :startGame="startGame"
                                :stopGame="stopGame"
                                :currentRoundDate="currentRoundDate"
                                _content-1=""
                                _nghost-kec-c65=""
                              >
                              </app-play-board>
                              <div class="multiplier-overlap" :class="this.multiplier_cls">
                                <div v-if="isDrawVisible" class="multiplier-round">
                                    <div class="round-text">{{ 'RUNDA: ' + currentRound }}</div>
                                </div>
                                <span v-if="isDrawVisible">{{ 'x' + multiplier.toFixed(2) }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              </app-game>
          </app-root>
        </div>
        <div class="countdown-modal" v-if="countdownSeconds <= 5 && countdownSeconds >= 1">
            <div id='inner-circle'>
              <div>{{ countdownSeconds }}</div>
            </div>
<!--             <div id='spinning-circle'>
              <img src="../assets/images/loading-black.png">
            </div> -->
        </div>
      </div>
      <div v-show="!this.$store.state.isDrawVisible" class="balls-results-content">
          <result-round
          v-if="this.$store.state.historyResultsRounds.length > 0"
          :historyResultsRounds = historyResultsRounds
          :currentRound = currentRound
          :countdownSeconds = countdownSeconds
          ></result-round>
          <div v-else>
            <waiting></waiting>
          </div>
<!--           <div
            v-else
            style="color: white; text-align: center; margin: auto auto; width: 100%;margin-top: 20%; font-size: 80px;"
          ><p>Va rugam asteptati!</p></div> -->
      </div>
    </div>
  <div
    v-else
    style="color: white; text-align: center; margin: auto auto; width: 100%;margin-top: 20%; font-size: 80px;"
  ><p>Se incarca jocul!</p><p>Va rugam asteptati!</p></div>
</template>

<script>
import './app-play-board'
import Maintenance from './Maintenance.vue'
import Disconnect from './Disconnect.vue'
import ResultRound from './ResultRound.vue'
import Waiting from './Waiting.vue'
import Jackpot from './Jackpot.vue'
export default {
  name: 'Draw',
  components: {
    Maintenance,
    Disconnect,
    ResultRound,
    Jackpot,
    Waiting
  },
  data() {
    return {
      multiplier_start: 1,
      multiplier_class: ''
    }
  },
  beforeMount() {
    this.loadSettings()
  },
  computed: {
    result: {
      get() {
        return this.$store.getters['result']
      }
    },
    multiplier_cls: {
      get() {
        this.getMultiplierClass()
        return this.multiplier_class
      }
    },
    betsClosed: {
      get() {
        return this.$store.getters['betsClosed']
      }
    },
    currentRound: {
      get() {
        return this.$store.getters['currentRound']
      }
    },
    countdownSeconds: {
      get() {
        return this.$store.getters['countdownSeconds']
      }
    },
    isDrawVisible: {
      get() {
        return this.$store.getters['isDrawVisible']
      }
    },
    startGame: {
      get() {
        return this.$store.getters['startGame']
      }
    },
    stopGame: {
      get() {
        return this.$store.getters['stopGame']
      }
    },
    multiplier: {
      get() {
        return this.$store.getters['multiplier']
      }
    },
    currentRoundDate: {
      get() {
        return this.$store.getters['currentRoundDate']
      }
    },
    historyResultsRounds: {
      get() {
        return this.$store.getters['historyResultsRounds']
      }
    }
  },
  methods: {
    resetJackpot: function (jackpot) {
      if (this.jackpot < this.$store.state.globalJackpot) {
        this.jackpot = jackpot + 1
        setTimeout(() => { this.resetJackpot(jackpot + 1) }, 100);
      }
      else
      {
         this.jackpot = this.$store.state.globalJackpot;
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
   async getMultiplierClass() {
      if (this.multiplier >= this.result && this.multiplier !== 1 || this.multiplier >= this.result && this.result == 1) {
        await this.delay(1000);
        this.multiplier_class = 'multiplier-fullsize'
      }
      else {
        this.multiplier_class = 'multiplier-overlap'
      }
    },
    loadSettings() {
      if (this.$store.state.settings.length == 0) {
        this.$store.dispatch('getSettings')
      }
    },  
    header: function () {
      return this.isDrawVisible == true ? "ballsix-header" : "ballsix-header2"
    },
    roundinfo() {
      return this.isDrawVisible == true ? "round-info" : "round-info2"
    },
    spaninfo() {
      return this.isDrawVisible == true ? "span-info" : "span-info2"
    }
  }
}
</script>
<style>
.jackpot-modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.maintenance-modal {
  position: absolute;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.disconnect-modal {
  position: absolute;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.draw-content {
  animation-name: fadeIn;
  animation-duration: 2s;
}
.round-history {
  padding-left: 15px;
}
.round-info
{
  float: left;
  line-height: 0px;
  width: 330px;
  height: 40px;
  padding-left: 10%;
  line-height: 1.9;
  text-align: left;
  color: white;
  font-weight: bold;
  font-size: 50px;
  text-shadow: 0.65px 0.25px black;
}
.span-info {
  width: 400px;
  margin-left: 30px;
  margin-right: auto;
  line-height: inherit;
  text-align: center;
  font-size: 50px;
}
.info-message {
  float: left;
  width: 880px;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  color: white;
  padding-top: 20px;
}
body {
        /* background: rgb(5, 35, 51); */
        background: rgb(51, 5, 18);
        color: white;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        animation-name: fadeIn;
        animation-duration: 2s;
      }
      .pbw[_content-1] {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
      }
      .pbw[_content-1]
        .sc[_content-1] {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border: 1px solid #2a2b2e;
        overflow: hidden;
      }
      .pbw[_content-1]
        .dc[_content-1] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      .pbw[_content-1]
        .dc[_content-1]
        .fun-mode[_content-1] {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(229, 148, 7, 0.8);
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        border-radius: 20px 20px 0 0;
        border: 1px solid #e59407;
      }
  .box-content {
    animation-name: fadeIn;
  animation-duration: 2s;
  }
  .multiplier-overlap {
    font-size: 200px;
    font-weight: 900;
    pointer-events: none;
    position: absolute;
    left: 5%;
    top: 40%;
    width: 50%;
    color: #E33E39;
    text-shadow: 0 20px 40px rgba(235, 129, 129, 0.5);
    white-space: nowrap;
    z-index: 3;
    text-align: center;
  }
  .multiplier-fullsize {
    font-size: 225px;
    font-weight: 900;
    pointer-events: none;
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100vw;
    height: 100vh;
    color: #E33E39;
    padding-top: 20%;
    text-align: center;
    text-shadow: 0 20px 40px rgba(235, 129, 129, 0.5);
    background-color: rgba(0, 0, 0, 0.958);
    white-space: nowrap;
    z-index: 999999;
  }

  .multiplier-round {
    background-color: inherit;
    font-size: 60px;
    text-shadow: #e59407;
    font-weight: 800;
    pointer-events: none;
    color: white;
    white-space: nowrap;
    z-index: 3;
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 1;
    padding: 10px;
  }
  .multiplier-round .round-text {
    font-size: 60px;
  }
  .current-round {
    font-size: 45px;
    text-shadow: #e59407;
    font-weight: 800;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    white-space: nowrap;
    z-index: 3;
    width: 100vw;
    height: 50px;
    background: black;
    text-align: center;
    line-height: 1;
    padding: 10px;
  }

  .current-round .countdown {
    float: right;
    width: 33%;
    line-height: 1;
    margin-right: 15px;
  }

  .current-round .round-number {
    float: left;
    width: 33%;
    text-align: left;
    line-height: 1;
    text-indent: 2%;
  }

  .current-round .logo {
    text-align: center;
    float: left;
    width: 33%;
    height: 60px;
    line-height: 60px;
    background-image: url("../assets/images/logopowerrocket.png");
    background-size: 100% 100%;
}

  .countdown-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.72);
    z-index: 9999;
  }

  #inner-circle {
      animation: pulse 1s infinite;
      margin: auto;
      margin-top: 20%;
      width: 300px;
      height: 300px;
      font-size: 200px;
      font-weight: bold;
      overflow: visible;
      line-height: 300px;
      text-align: center;
      transform: scale(1);
      border: 20px solid black;
      border-radius: 50%;
  }

/*   #spinning-circle {
      animation: spinning-circle linear 5s infinite;
      position: fixed;
      top: 340px;
      left:835px;
      width: 250px;
      height: 250px;
      overflow: visible;
      text-align: center;
      padding: 0px;
  } */

/*   img {
    width: 100%;
    height: auto;
  } */

  @keyframes pulse {
	0% {
		transform: scale(0.85);
    border: 15px solid darkred;
	}

	70% {
		transform: scale(1);
    border: 15px solid yellow;
	}

	100% {
		transform: scale(0.85);
    border: 15px solid blue;
	}
}
@keyframes box-width {
  0%   {width: 0%;}
  100% {width: 100%;}
}
@keyframes history-results {
  0%   {width:0vw;}
  100% {width: 100vw}
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
/* @keyframes spinning-circle {
    0% {
        transform: rotate(0turn);
    }
    100% {
        transform: rotate(-1turn);
    }
  } */
      
</style>
