<template>
  <div id="app">
    <Draw />
  </div>
</template>

<script>
import Draw from './components/Draw.vue'

export default {
  name: 'app',
  components: {
    Draw
},
beforeMount() {
    this.loadSettings()
  },
  methods: {
    loadSettings() {
      if (this.$store.state.settings.length == 0) {
        this.$store.dispatch('getSettings')
      }
    }
  }
}
</script>

<style>
* {
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#app {
  margin: 0px;
  padding: 0px;
  background-color: #a60d18;
}
</style>
