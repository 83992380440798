let settings = {
  development: {
    rocketServer:  //"https://tbet.oddin-platform.com/virtualsweb"
    "http://crash-game-stage.api-core.com"
    //"http://51.68.181.111:44395"
  },
  production: {
    rocketServer: "https://lgames.ibetplatform.com"
  },
  testingMode: false,
};

export default settings;
