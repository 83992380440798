<template>
  <transition name="jackpot-modal">
  <div class="jackpot-mask">
    <div class="jackpot-body">
            <div class="jackpot-info">
      <div class="round">Runda {{this.$store.state.jackpotRound}}</div>
      <div class="code">Cod {{this.$store.state.jackpotCode}}</div>
      <div class="amount">{{this.$store.state.jackpotValue}} LEI</div>
      <div class="shop">{{this.$store.state.jackpotShop}}</div>
            </div>
    </div>
  </div>
  </transition>
</template>

<script>
  export default {
    name: "Jackpot",
  }
</script>
<style scoped>

.jackpot-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
}
.jackpot-body {
    position: absolute;
    top: 0;
    left: 0;
    height: 1080px;
    width: 1920px;
    background-image: url("../assets/images/jackpot_background.png");
    background-size: 100% 100%;
    z-index: 9998;
}
.jackpot-info {
    display: block;
    height: 600px;
    width: 900px;
    text-align: center;
    margin-top: 19.5%;
    margin-left: auto;
    margin-right: auto;
    font-size: 60px;
    font-weight: bold;
    padding: 10px;
    font-family: "Arial";
    text-transform: uppercase;
    color: white;
}
.jackpot-info .round {
  margin-bottom: 16%;
}
.jackpot-info .code {
  margin-bottom: 17%;
}
</style>