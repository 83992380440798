<template>
  <div class="box-content">
    <div class="box-center">
      <div v-if="this.screenIndex <= 1" class="center-screen">
        <TopWins />
      </div>
      <div v-if="this.screenIndex > 1" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">CUM CÂȘTIGI?</p>
          <p
            class="round-text"
            style="width: 50%; text-align: center; margin: auto"
          >
            Ai cota pariată pe bilet mai mică sau egală decât cota afișată pe
            ecran?
          </p>
          <p
            class="round-text"
            style="width: 50%; text-align: center; margin: auto"
          >
            Felicitări! Ai câștigat!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopWins from "./table/TopWins.vue";
export default {
  name: "Waiting",
  components: { TopWins },
  data() {
    return {
      screens: ["odds-frequency", "odds-frequency", "help1"],
      currentScreen: "odds-frequency",
      screenIndex: 0,
      styles: {
        backgroundColor: "transparent",
      },
    };
  },
  beforeMount() {
    this.screenShowcase();
  },
  methods: {
    screenShowcase: function () {
      if (this.screenIndex < this.screens.length - 1) {
        this.screenIndex += 1;
      } else {
        this.screenIndex = 0;
      }
      setTimeout(() => {
        this.screenShowcase();
      }, 5000);
    },
  },
};
</script>

<style scoped>
.jackpot-modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.maintenance-modal {
  position: absolute;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.disconnect-modal {
  position: absolute;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.box-content {
  display: block;
  width: 0px;
  height: 1080px;
  width: 1920px;
  /* background:rgb(5, 55, 82); */
  /*   background-image: url("../assets/images/background.png"); */
  background-size: 100% 100%;
  margin: auto;
}
.ballsix-header-center {
  width: 1120px;
  height: 45px;
  float: left;
}
.box-center {
  text-align: center;
  box-sizing: border-box;
  float: left;
  width: 100vw;
  height: 100vw;
  margin-left: auto;
  margin-right: auto;
}
.round-history {
  padding-left: 15px;
}
.history-results {
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100px;
  text-align: center;
  animation-name: history-results;
  animation-duration: 1s;
}
.history-result {
  position: relative;
  background: rgb(5, 35, 51);
  height: fit-content;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 5px;
}
.history-result p {
  width: 100%;
  font-size: 25px;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.history-result .round {
  font-size: 15px;
}
.center-screen {
  width: 100vw;
  height: 100vw;
}
.last-round-result {
  position: relative;
  top: 10%;
  left: 0;
  color: white;
  width: 100vw;
  text-align: center;
  height: fit-content;
  font-size: 70px;
  font-weight: bold;
  animation-name: fadeIn;
  animation-duration: 2s;
}
.last-round-result p {
  margin: 0px;
  padding: 15px;
}
.text-result {
  font-size: 30px;
}
.round-text {
  font-size: 50px;
}
.chart {
  margin: auto;
  width: 1000px;
  height: 1000px;
}
</style>
