<template>
  <div class="table-container">
    <div
      class="title"
      style="
        width: 50%;
        display: inline-block;
        text-align: right;
        box-sizing: border-box;
        padding-right: 20px;
      "
    >
      <span style="margin: 0; padding: 0"
        >TOP BILETE <br />
        CÂȘTIGĂTOARE</span
      >
    </div>
    <div
      class="title"
      style="width: 50%; display: inline-block; text-align: left"
    >
      <span
        style="
          margin: 0;
          box-sizing: border-box;
          padding-left: 20px;
          font-size: 80px;
          width: 50%;
        "
        >7 Zile</span
      >
    </div>
    <table>
      <tr v-for="(win, index) in this.$store.state.topWins" :key="index">
        <td style="text-align: left; padding-left: 40px">
          <span class="circle"></span
          ><span class="location">{{ win.shopName }}</span>
        </td>
        <td class="date-time">
          <p style="color: lightgray; font-weight: lighter">
            {{ formatDateDD(win.ticketDate) }}
          </p>
          <p>{{ formatDateHH(win.ticketDate) }}</p>
        </td>
        <td>
          <p class="td-title">Pariu LEI</p>
          <p class="amount">{{ win.playedAmount }}</p>
        </td>
        <td class="amount">
          <p class="td-title">Câștig LEI</p>
          <p class="amount-won">{{ win.winAmount }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { formatDateDDMM } from "../../utils/index";
import { formatDateHHmm } from "../../utils/index";
export default {
  name: "TopWins",
  methods: {
    formatDateDD: function (date) {
      return formatDateDDMM(date);
    },
    formatDateHH: function (date) {
      return formatDateHHmm(date);
    },
  },
};
</script>
<style>
.table-container {
  /* Slightly transparent background */
  border-radius: 10px;
  padding: 20px;
  color: white;
  width: 80%;
  margin: auto;
}
.number {
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
.title {
  font-size: 35px;
  text-align: center;
  margin-bottom: 15px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
tr {
  width: 100%;
  height: 100%;
}
td {
  padding: 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 32px;
  white-space: nowrap;
  vertical-align: middle;
  margin: auto;
}
td:first {
  text-align: left;
}
.circle {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.location {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date-time {
  text-align: center;
}
.multiplier {
  font-weight: bold;
  color: #fbbf24;
  text-align: center;
}
.amount {
  text-align: center;
}
.amount-won {
  text-align: center;
  color: #fbbf24;
}
.td-title {
  color: lightgray;
  font-weight: lighter;
  margin: 0;
  padding: 0;
}
td p {
  margin: 0;
}
</style>
