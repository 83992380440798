<template>
  <transition name="disconnect-modal">
  <div class="disconnect-mask">
    <div class="disconnect-body" style="color: white; text-align: center; margin: auto; width: 1920px; font-size: 80px;">
      <p style="margin-top: 20%;">CONECTARE ESUATA!</p>
      <p>SE INCEARCA RECONECTAREA...</p>
    </div>
  </div>
  </transition>
</template>

<script>
  export default {
    name: "Disconnect",
  }
</script>
<style scoped>

.disconnect-mask {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
}
.disconnect-body {
    position: absolute;
    top: 0;
    left: 0;
    height: 1080px;
    width: 1920px;
    background: black;
    background-size: 100% 100%;
    z-index: 20000;
    font-family: "Arial";
}
</style>