<template>
  <div class="box-content">
    <div class="current-round">
      <div class="round-number">{{ this.$store.state.headerText }}</div>
      <div class="logo"></div>
      <div class="countdown" v-if="countdownSeconds > 5">
        TIMP RĂMAS: {{ formatCountdown(countdownSeconds) }}
      </div>
    </div>
    <div class="jackpots">
      <div class="globaljackpot" v-if="this.$store.state.globalJackpot > 0">
        <p class="jackpot-label">JACKPOT GLOBAL</p>
        <p class="jackpot-value">{{ this.$store.state.globalJackpot }} RON</p>
      </div>
      <div class="localjackpot" v-if="this.$store.state.localJackpot > 0">
        <p class="jackpot-label">JACKPOT LOCAL</p>
        <p class="jackpot-value">{{ this.$store.state.localJackpot }} RON</p>
      </div>
    </div>
    <div class="box-center">
      <div v-if="screenShowcase == 'last-round'" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">ULTIMUL REZULTAT</p>
          <p>{{ historyResultsRounds[0]["result"] }}</p>
          <p class="round-text">
            RUNDA {{ historyResultsRounds[0]["roundNo"] }}
          </p>
        </div>
      </div>
      <div
        v-if="screenShowcase == 'odds-frequency' || screenShowcase == 'help1'"
        class="center-screen"
      >
        <div class="last-round-result-2">
          <div class="last-round-result">
            <TopWins />
          </div>
        </div>
      </div>
      <div v-if="screenShowcase == 'help2'" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">CUM CÂȘTIGI?</p>
          <p
            class="round-text"
            style="width: 60%; text-align: center; margin: auto"
          >
            Ai cota pariată pe bilet mai mică sau egală decât cota afișată pe
            ecran?
          </p>
          <p
            class="round-text"
            style="width: 50%; text-align: center; margin: auto"
          >
            Felicitări! Ai câștigat!
          </p>
        </div>
      </div>
      <div class="history-results">
        <div style="font-size: 35px; margin-left: auto; margin-right: auto">
          Istoric <br />
          runde
        </div>
        <div class="history-grid">
          <div
            v-for="result in historyResultsRounds"
            :key="result.roundNo"
            class="history-result"
          >
            <div :class="getMultiplierColorClass(result.result)">
              {{ result.result }}x
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopWins from "./table/TopWins.vue";
export default {
  name: "ResultRound",
  components: { TopWins },
  props: {
    historyResultsRounds: Array,
    currentRound: Number,
    countdownSeconds: Number,
  },
  data() {
    return {
      screens: ["last-round", "odds-frequency", "help1", "help2"],
      currentScreen: "last-round",
      styles: {
        backgroundColor: "transparent",
      },
    };
  },
  computed: {
    screenShowcase: {
      get() {
        let result;
        if (this.countdownSeconds > 20) {
          result = this.screens[0];
        }
        if (this.countdownSeconds >= 15 && this.countdownSeconds <= 20) {
          result = this.screens[1];
        }
        if (this.countdownSeconds >= 10 && this.countdownSeconds < 15) {
          result = this.screens[2];
        }
        if (this.countdownSeconds >= 5 && this.countdownSeconds < 10) {
          result = this.screens[3];
        }
        return result;
      },
    },
  },
  methods: {
    formatCountdown: function (countdownSeconds) {
      let minutes = parseInt(countdownSeconds / 60);
      let seconds = countdownSeconds % 60;
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes > 0 ? minutes + ":" + seconds : "0:" + seconds;
    },
    getMultiplierColorClass(value) {
      if (value < 2) {
        return "round-0";
      }

      if (value >= 2 && value < 5) {
        return "round-2";
      }

      if (value >= 5 && value < 10) {
        return "round-5";
      }

      if (value >= 10 && value < 100) {
        return "round-10";
      }
      if (value >= 100) {
        return "round-100";
      }
    },
  },
};
</script>

<style scoped>
.jackpot-modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.maintenance-modal {
  position: absolute;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.disconnect-modal {
  position: absolute;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.box-content {
  display: block;
  width: 0px;
  height: 1080px;
  width: 1920px;
  /*   background-image: url("../assets/images/background.png"); */
  background-size: 100% 100%;
  margin: auto;
}
.ballsix-header-center {
  width: 1120px;
  height: 45px;
  float: left;
}
.box-center {
  text-align: center;
  box-sizing: border-box;
  float: left;
  width: 100vw;
  height: 100vw;
  margin-left: auto;
  margin-right: auto;
}
.round-history {
  padding-left: 15px;
}
.history-results {
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100px;
  text-align: center;
  animation-name: history-results;
  background: rgb(5, 35, 51);
  animation-duration: 1s;
  padding: 5px;
}
.history-grid {
  display: grid;
  row-gap: 5px;
  column-gap: 4px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
}
.history-result {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.history-result div {
  width: auto;
  font-size: 30px;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.center-screen {
  width: 100vw;
  height: 100vw;
}
.last-round-result,
.last-round-result-2 {
  position: relative;
  top: 15%;
  left: 0;
  color: white;
  width: 100vw;
  text-align: center;
  height: fit-content;
  font-size: 80px;
  font-weight: bold;
  animation-name: fadeIn;
  animation-duration: 2s;
}
.last-round-result-2 {
  top: 8%;
}
.last-round-result p {
  margin: 0px;
  padding: 15px;
}
.text-result {
  font-size: 40px;
}
.round-text {
  font-size: 55px;
}
.chart {
  margin: auto;
  width: 1000px;
  height: 1000px;
}
.localjackpot {
  position: fixed;
  top: 70px;
  right: 0px;
  color: white;
  width: 25%;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px black;
  font-size: 40px;
  background-color: black;
  border-bottom-left-radius: 30px;
  border-top: 3px solid #a60d18;
}
.globaljackpot {
  position: fixed;
  top: 70px;
  left: 0px;
  width: 25%;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px black;
  font-size: 40px;
  background-color: black;
  border-bottom-right-radius: 30px;
  border-top: 3px solid #a60d18;
}
.jackpot-label {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
.jackpot-value {
  padding: 0;
  margin: 0;
  color: goldenrod;
}
.round-0 {
  color: gray;
}
.round-2 {
  color: yellow;
}
.round-5 {
  color: lawngreen;
}
.round-10 {
  color: skyblue;
}
.round-100 {
  color: violet;
}
</style>
