export function formatDateDDMM(stringDate) {
  var date = new Date(stringDate);
  let day = date.getDate();
  let dayString = day > 9 ? day.toString() : "0" + day;
  let month = date.getMonth() + 1;
  let monthString = month > 9 ? month.toString() : "0" + month;
  return `${dayString}-${monthString}`;
}

export function formatDateHHmm(stringDate) {
  var date = new Date(stringDate);
  let hours = date.getHours();
  var minutes = date.getMinutes();
  let hoursString = hours > 9 ? hours.toString() : "0" + hours;
  let minutesString = minutes > 9 ? minutes.toString() : "0" + minutes;
  return `${hoursString}:${minutesString}`;
}
